import {
    CheckCircleIcon,
    XCircleIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import toast, { resolveValue, Toast as ToastType } from "react-hot-toast";
import { FormattedMessage } from "react-intl";

export default function Toast({ t }: { t: ToastType }) {
    return (
        <div
            {...t.ariaProps}
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-999"
        >
            <div
                style={{ opacity: t.visible ? 1 : 0 }}
                className="flex w-full flex-col items-center space-y-4 sm:items-end"
            >
                <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5">
                    <div className="p-4">
                        <div className="flex items-start">
                            <div className="shrink-0">
                                {t.type === "success" && (
                                    <CheckCircleIcon className="size-6 text-green-400" />
                                )}
                                {t.type === "error" && (
                                    <XCircleIcon className="size-6 text-green-400" />
                                )}
                            </div>
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-sm font-medium text-gray-900">
                                    {resolveValue(t.message, t)}
                                </p>
                            </div>
                            <div className="ml-4 flex shrink-0">
                                <button
                                    type="button"
                                    onClick={() => toast.dismiss(t.id)}
                                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    <span className="sr-only">
                                        <FormattedMessage id="close" />
                                    </span>
                                    <XMarkIcon className="size-5" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
